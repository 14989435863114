<template>
  <v-app id="app">
    <!-- 사이드 컴포넌트 -->
    <v-navigation-drawer
      v-if="window.width > 480"
      permanent
      app
      fixed
      width="240"
      class="left-drawer"
      :class="{ on: isDrawerOpen }"
    >
      <v-list>
        <v-list-item class="logo-item" style="justify-content: center">
          <img
            class="mainLogo"
            :src="require(`@/assets/images/logo${pineDx}.svg`)"
            :alt="`Pine-DX${pineDx}`"
          />
        </v-list-item>
        <v-list-item class="item-spacing userName">
          <span class="user-name">{{ userNm }}({{ userId }})</span
          ><span>님</span>
        </v-list-item>
        <v-list-item class="item-spacing myPage" @click="openMyPagePopup()">
          <span class="">마이페이지</span>
          <v-icon class="mdi">mdi-account-circle</v-icon>
        </v-list-item>
        <v-list-item class="item-spacing logout mt16" link @click="logout()">
          <span>로그아웃</span>
          <v-icon class="mdi">mdi-logout</v-icon>
        </v-list-item>
      </v-list>

      <ul id="gnb">
        <li
          v-for="(item, i) in menuList"
          :key="i"
          :id="'gd_gnb' + i"
          :class="{ active: isActiveMenu(item.to) }"
        >
          <router-link
            :to="item.to"
            class="dep01"
            :class="[item.icon]"
            :event="item.text === '환자관리' ? [] : ['click']"
            @click.native="onMenuClick(item, $event)"
          >
            <i :class="item.icon"></i>
            {{ item.text }}
          </router-link>
        </li>
      </ul>

      <ul class="etcList" v-if="pineDx === 'S'">
        <!-- <li><a href="#none">도움말<i class="micon">help</i></a></li> -->
        <li @click="openInfoPopup()">
          <span><i class="micon">info</i>솔루션 정보</span>
        </li>
      </ul>
    </v-navigation-drawer>

    <v-main :class="{ on: isDrawerOpen }">
      <Header
        v-if="window.width > 280 && ShowHeader"
        :type="type"
        :title="title"
        :path="path"
        class="header"
      />

      <div class="content">
        <transition name="page" mode="out-in">
          <router-view />
        </transition>
      </div>

      <!-- 사이드바 오픈 버튼 -->
      <button
        class="toggle-button"
        v-if="useRightToggle"
        @click="toggleRightSidebar"
        v-ripple
      >
        <i class="micon" :class="{ on: rightSide }">navigate_before</i>
      </button>

      <button
        class="leftSideBtn"
        @click="toggleDrawer"
        :class="{ on: isDrawerOpen }"
        v-ripple
      >
        <i class="micon">navigate_before</i>
      </button>
    </v-main>

    <!-- 오른쪽 사이드바 -->
    <right-side-compo
      v-if="window.width > 480 && useRightToggle"
      :drawer="rightSide"
      :userId="userId"
    />

    <!-- 비밀번호 변경 팝업 -->
    <v-dialog v-model="chgPwPopupVisible">
      <ChangePwPopup
        :onClick="
          () => {
            popupVisible = false;
          }
        "
        :userNo="userNo"
        :initialValues="initialValues"
        :initPwYn="initPwYn"
        @closePopup="closeChangePwPopup"
      />
    </v-dialog>

    <!-- 솔루션 정보 팝업 -->
    <v-dialog v-model="infoPopupVisible">
      <InfoPopup @closeInfoPopup="closeInfoPopup" />
    </v-dialog>

    <!-- 마이페이지 팝업 -->
    <v-dialog v-model="mypagePopupVisible">
      <MyPagePopup @closeMyPagePopup="closeMyPagePopup" />
    </v-dialog>
    <!-- 환자관리 이동 확인 팝업 -->
    <v-dialog v-model="confirmPopupVisible">
      <div class="popup confirm-popup">
        <div class="box">
          <div class="popTitle popTitleArea">
            <p><i class="micon">info</i>확인</p>
          </div>
          <div class="popupContArea">
            <div class="textArea">
              <p>환자관리로 이동하시겠습니까?</p>
              <p>이동 시 현재 업로드된 이미지가 유지되지 않습니다.</p>
            </div>
            <div class="btnW popBtn">
              <button class="btn default" @click="closeConfirmPopup" v-ripple>
                취소
              </button>
              <button class="btn solid" @click="confirmNavigation" v-ripple>
                이동하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </v-app>
</template>

<script>
import { rules } from "../mixin/rules";

export default {
  name: "App",
  mixins: [rules],
  props: {
    type: {
      default: "",
    },
  },
  provide() {
    return {
      getShowHeader: this.getShowHeader,
      setShowHeader: this.setShowHeader,
      getRightSideHidden: this.getRightSideHidden,
      setRightSideHidden: this.setRightSideHidden,
    };
  },
  computed: {
    useRightToggle() {
      let result = true;
      if (
        (this.$route.path.startsWith("/patients") &&
          !this.$route.path.includes("/record")) ||
        this.$route.path.startsWith("/admin")
      )
        result = false;
      return result;
    },
  },
  components: {
    ChangePwPopup: () => import("../popup/changePwPopup.vue"),
    InfoPopup: () => import("../popup/infoPopup.vue"),
    MyPagePopup: () => import("../popup/myPagePopup.vue"),
  },
  data() {
    return {
      ShowHeader: true,
      rightSide: false,
      menuList: [],
      userMenu: [
        { text: "AI 분석", icon: "ai", to: "/reading" },
        { text: "환자관리", icon: "cons", to: "/patients" },
      ],
      adminMenu: [
        { text: "사용자 계정 관리", icon: "adms", to: "/admin/userManage" },
        {
          text: "환자 이관신청 관리",
          icon: "req",
          to: "/admin/transferManage",
        },
        { text: "전체 환자 관리", icon: "cons", to: "/admin/patientManage" },
      ],
      selectedItem: "",
      isDrawerOpen: false,
      title: "",
      path: this.$route.path,
      userNo: "",
      userNm: "",
      userId: "",
      chgPwPopupVisible: false,
      initialValues: {
        newPw: "",
        chkPw: "",
      },
      pineDx: process.env.VUE_APP_PINE_DX,
      infoPopupVisible: false,
      initPwYn: "N",
      mypagePopupVisible: false,
      confirmPopupVisible: false,
      nextRoute: null,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        let path = this.$route.path;
        this.getTitle(path);
      }
    },
    initPwYn(newValue) {
      this.checkInitPw();
    },
  },
  created() {},
  mounted() {
    this.setMenu();
    this.getTitle(this.$route.path);
    if (this.userInfo) {
      this.userNm = this.userInfo.userNm;
      this.userNo = this.userInfo.no;
      this.userId = this.userInfo.loginId;
      this.initPwYn = this.userInfo.initPwYn;
    }
  },
  activated() {},
  deactivated() {},
  destroyed() {},
  methods: {
    toggleRightSidebar() {
      this.rightSide = !this.rightSide;
    },
    getShowHeader() {
      return this.ShowHeader;
    },
    setShowHeader(data) {
      this.ShowHeader = data;
    },
    getRightSideHidden() {
      return this.rightSide;
    },
    setRightSideHidden(data) {
      this.rightSide = data;
    },
    isActiveMenu(menuPath) {
      return this.$route.path.startsWith(menuPath);
    },
    toggleDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen;
    },
    logout() {
      this.initLocalStorage();
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "login" }).catch((err) => {
        this.$router.go();
      });
    },
    getTitle(path) {
      for (let menu of this.menuList) {
        if (path.includes(menu.to)) {
          this.title = menu.text;
          this.path = path;
        }
      }
    },
    setMenu() {
      const role = this.$store.getters["auth/userRole"];
      if (role) {
        if (role === "A") this.menuList = this.adminMenu;
        else this.menuList = this.userMenu;
      }
    },
    openChangePwPopup() {
      this.chgPwPopupVisible = true;
    },
    closeChangePwPopup() {
      this.chgPwPopupVisible = false;
    },
    openInfoPopup() {
      this.infoPopupVisible = true;
    },
    closeInfoPopup() {
      this.infoPopupVisible = false;
    },
    checkInitPw() {
      if (this.userInfo && this.userInfo.initPwYn) {
        this.initPwYn = this.userInfo.initPwYn;
        if (this.initPwYn === "Y") this.chgPwPopupVisible = true;
      }
    },
    openMyPagePopup() {
      this.mypagePopupVisible = true;
    },
    closeMyPagePopup() {
      this.mypagePopupVisible = false;
    },
    onMenuClick(item, event) {
      if (item.text === "환자관리") {
        const xrayMap = localStorage.getItem("xrayMap");
        if (xrayMap && Object.keys(JSON.parse(xrayMap)).length > 0) {
          this.confirmPopupVisible = true;
          this.nextRoute = item.to;
        } else {
          this.$router.push(item.to);
        }
      } else {
      }
    },
    closeConfirmPopup() {
      this.confirmPopupVisible = false;
    },
    confirmNavigation() {
      this.confirmPopupVisible = false;
      // 이미지 업로드 정보를 초기화합니다.
      localStorage.removeItem("xrayMap");
      localStorage.removeItem("pictureDt");
      localStorage.removeItem("uploadedXrayType");
      // 저장된 경로로 이동합니다.
      this.$router.push(this.nextRoute);
    },
  },
};
</script>
<style lang="scss">
@import "~shepherd.js/dist/css/shepherd.css";
@import "../sass/app.css";
@import "../sass/guide.css";
@import "@/sass/app.scss";
.page-enter-active,
.page-leave-active {
  transition: opacity 0.2s;
}
.page-enter,
.page-leave-to {
  opacity: 0;
}

.full-height {
  flex-grow: 1;
  height: calc(100% - 170px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.left-drawer {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.logo {
  width: 100%;
  padding: 5px;
}
.logo-item {
  margin-bottom: 40px;
}
.item-spacing {
  min-height: 32px;
  font-size: 18px;
  color: rgba(236, 240, 241, 0.4) !important;
  i {
    color: rgba(236, 240, 241, 0.4) !important;
    font-size: 16px !important;
    margin-left: 5px;
  }
  .user-name {
    font-size: 18px;
    font-weight: 500;
    margin-right: 5px;
    color: #fff;
  }
  &:not(:first-child):not(:nth-child(2)):hover {
    span {
      color: #fff;
      transition: 0.3s;
    }
    i {
      color: #fff !important;
    }
  }
}

.v-list-item__icon:first-child {
  margin: 8px 8px 8px 12px !important;
}
.v-list-item__title {
  font-size: 16px !important;
  line-height: normal !important;
}
.list-item-hover {
  color: rgba(236, 240, 241, 0.4) !important;
  margin: 10px 0px 10px 5px;
  padding: 10px 5px 10px 5px !important;
  position: relative;
  &:hover {
    div {
      color: rgba(63, 72, 237, 0.8);
      transition: 0.5s;
    }
  }
}
.list-item-hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0%;
  width: 0;
  height: 100%;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right;
  transition: width 0.5s ease, left 0.5s ease;
  border-radius: 12px !important;
  z-index: -1;
}
.list-item-hover:not(.active):hover::before {
  width: 100%;
  background-color: #fff;
  opacity: 1 !important;
}
.list-item-hover::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: width 0.5s ease, right 0.5s ease;
  border-radius: 12px !important;
  opacity: 0;
}
.list-item-hover:not(:hover):not(.active)::after {
  width: 0;
  right: 100%;
  opacity: 1;
  z-index: -1;
}

/*좌측영역 여닫기*/
.leftSideBtn {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 100px;
  background: var(--color-p40);
  border-radius: 0 8px 8px 0;
  transition: box-shadow 0.5s, opacity 0.5s;
  .micon {
    color: #fff;
  }
}
.leftSideBtn.on {
  .micon {
    transform: rotate(180deg);
  }
}
.leftSideBtn:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  opacity: 0.7;
}
.left-drawer.on {
  transform: translateX(-100%) !important;
}
.v-main.on {
  padding-left: 0 !important;
}

/*우측영역 여닫기*/
.toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 100px;
  background: var(--color-p40);
  border-radius: 8px 0 0 8px;
  transition: box-shadow 0.5s, opacity 0.5s;
  .micon {
    color: #fff;
  }
  .micon.on {
    transform: rotate(180deg);
  }
}
.toggle-button:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  opacity: 0.7;
}

.content {
  height: calc(100% - 80px);
}
.popup.confirm-popup {
  .box {
    width: 580px;
    .popTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        display: flex;
        align-items: center;
        i {
          margin-right: 8px;
        }
      }
    }
    .popupContArea {
      padding: 24px;
      .textArea {
        margin-bottom: 24px;
        p {
          text-align: center;
          font-size: 15px;
          line-height: 2rem;
          font-weight: bold;
        }
      }
      .popBtn {
        padding-bottom: 0;
      }
    }
  }
}
</style>
